import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>NOT FOUND</h1>
      <p>The page you are looking for does not exit.</p>
      <div className="btn-group">
        <a href="tel:9725625788" class="btn-red" style={{zIndex: `2`}}>Call us Today</a>
        <a href="#contact" className="btn" style={{zIndex: `1`}}>Contact Us Online</a>
    </div>
    </div>
  </Layout>
);

export default NotFoundPage;
